const styleSheetsArray = Object.entries({
  ".hotel-royal-level": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelRoyalLevel.css", false],
  ".c-hightlights-services-banner": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hightlightServiceBanner.css", false],
  ".c-hotel-card": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelCard.css", false],
  ".mod--horizontal-card-compact": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelHorizontalCompact.css", false],
  ".c-meeting__table, .c-meeting__modal, .meeting__table": ["/etc.clientlibs/barcelo/clientlibs/clientlib-meetingTable.css", false],
  ".c-flip-card": ["/etc.clientlibs/barcelo/clientlibs/clientlib-flipCard.css", false],
  ".c-card-hotel, .c-card-hotel-list, .c-label--tag-all-included": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotel.css", false],
  ".cmp-breadcrumb, .breadcrumb": ["/etc.clientlibs/barcelo/clientlibs/clientlib-breadcrumb.css", false],
  ".c-textimage, .textimage": ["/etc.clientlibs/barcelo/clientlibs/clientlib-textImage.css", false],
  ".c-label": ["/etc.clientlibs/barcelo/clientlibs/clientlib-label.css", false],
  ".c-submenu-container": ["/etc.clientlibs/barcelo/clientlibs/clientlib-submenuContainer.css", false],
  ".c-card-hotel-list__content, .c-card-hotel-list": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelList.css", false],
  ".info-service__wrapper, .c-info-service": ["/etc.clientlibs/barcelo/clientlibs/clientlib-infoServicePage.css", false],
  ".meetings-comparator": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cardHotelCompactMeeting.css", false],
  ".offer-collection-list, .meetings-comparator": ["/etc.clientlibs/barcelo/clientlibs/clientlib-offerCollectionList.css", false],
  ".c-collection-list": ["/etc.clientlibs/barcelo/clientlibs/clientlib-collectionList.css", false],
  "footer, .footer, .xpf_reference_footer": ["/etc.clientlibs/barcelo/clientlibs/clientlib-footer.css", false],
  ".c-container, .generic-container": ["/etc.clientlibs/barcelo/clientlibs/clientlib-container.css", false],
  ".c-modal, .tingle-modal": ["/etc.clientlibs/barcelo/clientlibs/clientlib-customModal.css", false],
  ".c-carousel": ["/etc.clientlibs/barcelo/clientlibs/clientlib-carousel.css", false],
  header: ["/etc.clientlibs/barcelo/clientlibs/clientlib-header.css", false],
  ".c-myb-menu, .c-myb-menu--login": ["/etc.clientlibs/barcelo/clientlibs/clientlib-cMybMenu.css", false],
  ".c-coupon": ["/etc.clientlibs/barcelo/clientlibs/clientlib-coupon.css", false],
  ".c-myb-current-bookings": ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybCurrentBooking.css", false],
  ".c-myb-booking-history": ["/etc.clientlibs/barcelo/clientlibs/clientlib-mybBookingHistory.css", false],
  ".advantages-cards": ["/etc.clientlibs/barcelo/clientlibs/clientlib-advantagesCards.css", false],
  ".c-hotel-related": ["/etc.clientlibs/barcelo/clientlibs/clientlib-hotelRelated.css", false],
  ".bhg-loading": ["/etc.clientlibs/barcelo/clientlibs/clientlib-loadings.css", false],
}).map(([selector, [styleSheet, emitEventOnLoad]]) => ({
  selector,
  styleSheet,
  emitEventOnLoad,
  splitSelectors: selector.split(",").map((s) => s.trim()),
}));

const loadDynamicStyles = (styleSheet, selectors, emitEventOnLoad) => {
  selectors.forEach((selectorClass) => {
    bcl.u.loadStyleSheet(styleSheet, selectorClass, emitEventOnLoad);
  });
};

const checkAndLoad = ({ splitSelectors, styleSheet, emitEventOnLoad }, root = document) => {
  if (splitSelectors.some((sel) => root.querySelector(sel))) {
    loadDynamicStyles(styleSheet, splitSelectors, emitEventOnLoad);
  }
};

const loadExistingStyles = () => {
  styleSheetsArray.forEach((entry) => {
    checkAndLoad(entry, document);
  });
};

const handleMutations = (mutationsList) => {
  const addedElements = mutationsList
    .filter((mutation) => mutation.type === "childList")
    .flatMap((mutation) => Array.from(mutation.addedNodes))
    .filter((node) => node.nodeType === Node.ELEMENT_NODE);

  if (addedElements.length === 0) {
    return;
  }

  styleSheetsArray.forEach(({ splitSelectors, styleSheet, emitEventOnLoad }) => {
    addedElements.some((el) => {
      if (splitSelectors.some((sel) => el.matches(sel) || el.querySelector(sel))) {
        loadDynamicStyles(styleSheet, splitSelectors, emitEventOnLoad);
        return true; // Break loop
      }
      return false;
    });
  });
};

const startDynamicObservation = () => {
  loadExistingStyles();

  const mutationObserver = new MutationObserver(handleMutations);
  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

export const checkDynamicComponents = startDynamicObservation;
